<i18n>
ru:
  added: 'Добавлено: {product}'
ua:
  added: 'Додано: {product}'
us:
  added: 'Added: {product}'
</i18n>

<template>
  <div
    class="v-menu-cart-notifications"
    :style="style"
  >
    <div
      v-for="message in messagesToShow"
      :key="sanitize(message.text)"
      class="v-menu-cart-notifications--single"
      data-test-id="cart-notification-message"
      v-html="sanitize(message.text)"
    />
  </div>
</template>

<script setup lang="ts">
import type { CartItem } from '~types/clientStore'

import { PositionType } from '~api/consts'

type Message = {
  timerId: ReturnType<typeof setTimeout>
  text: string
}

const clientStore = useClientStore()

const appConfig = useAppConfig()
const { sanitize, translate } = useI18nSanitized()

onMounted(() => {
  clientStore.initClientState()

  if (import.meta.client) document.addEventListener('scroll', updateStyle)
})

function updateStyle(): void {
  style.value = `top: ${(document.querySelector('#header-groups')?.getBoundingClientRect()?.bottom ?? 0) + 14}px;`
  style.value += `animation-duration: ${appConfig.VueSettingsPreRun.MenuNotificationDurationMS / 1000}s;`
}

const messagesToShow = ref<Message[]>([])

const style = ref<string>('')
function showOnAddAnimation(addedItem: CartItem): void {
  messagesToShow.value.push({
    text: translate('notifications.added', { product: addedItem.Name }),
    timerId: setTimeout(
      () => messagesToShow.value.shift(),
      appConfig.VueSettingsPreRun.MenuNotificationDurationMS
    )
  })

  if (
    messagesToShow.value.length > appConfig.VueSettingsPreRun.MenuNotificationLimit
  ) {
    const itemToRemove = messagesToShow.value[0]
    clearInterval(itemToRemove.timerId)
    messagesToShow.value.shift()
  }
}

watch(
  () => clientStore.ClientState.data?.Cart?.Content,
  (newContent: CartItem[] | undefined, oldContent: CartItem[] | undefined) => {
    if (!oldContent) return // after initialization oldContent is null
    if (!newContent) return // in case of client state update error

    if (oldContent !== newContent) {
      // find a new or updated item
      const newContentFiltered = newContent.filter(
        (item: CartItem) => item.PositionType !== PositionType.Additional
      )
      const oldContentFiltered = oldContent.filter(
        (item: CartItem) => item.PositionType !== PositionType.Additional
      )

      const newItems = newContentFiltered.filter((item: CartItem) => {
        return !oldContentFiltered.some((oldItem: CartItem) => {
          return oldItem.ID === item.ID && item.Count <= oldItem.Count // wasn't added
        })
      })

      for (const newItem of newItems) {
        showOnAddAnimation(newItem)
      }
    }
  },
  { deep: true, immediate: true }
)
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-menu-cart-notifications {
  position: fixed;
  right: 25px;
  transition: all 0.3s ease;
  width: 250px;
  z-index: 1012;

  &--single {
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 1.1rem;
    border-radius: variables.$BorderRadius;
    display: flex;
    margin-bottom: 10px;
    transform-origin: 50% 50%;
    font-size: 1.1rem;

    animation-name: animationFrames;
    animation-timing-function: ease;
    animation-iteration-count: 1;
  }
}

@include mixins.sm {
  .v-menu-cart-notifications {
    left: 0;
    right: 0;
    width: auto;
    max-width: none;
    min-width: unset;

    &--single {
      border-radius: 0;
    }
  }
}

@keyframes animationFrames {
  0% {
    opacity: 0;
    transform: translate(0px, -25px);
  }
  15% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  85% {
    opacity: 1;
    transform: translate(0px, 0px);
    margin-bottom: 10px;
    padding: 15px;
    font-size: 16px;
  }
  100% {
    margin-bottom: 0;
    font-size: 0;
    padding: 0;
    opacity: 0;
    transform: translate(0px, -25px);
  }
}
</style>
